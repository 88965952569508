import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const HtmlHeader = ({ title, description, image }) => {
  if (!title) title = "Volkswirt | Berater | Dozent";
  return (
    <Helmet>
      <title>{title} - Johannes Volk</title>
      <meta property="og:title" content={`${title} - Johannes Volk`} />
      <meta name="twitter:title" content={`${title} - Johannes Volk`} />
      <meta
        property="og:url"
        content={`https://www.hannes-volk.de${useLocation().pathname}`}
      />
      {description ? <meta name="description" content={description} /> : null}
      {description ? (
        <meta property="og:description" content={description} />
      ) : null}
      {description ? (
        <meta name="twitter:description" content={description} />
      ) : null}
      {image ? <meta property="og:image" content={image} /> : null}
      {image ? <meta name="twitter:image" content={image} /> : null}
    </Helmet>
  );
};

export default HtmlHeader;
