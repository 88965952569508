import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const ServiceContainer = ({ title, description, list }) => (
  <Container>
    <Row>
      <Col lg={4} className="mb-4 mb-lg-0 text-center text-lg-left">
        <h2>{title}</h2>
        {description && <p className="mb-0 lead">{description}</p>}
      </Col>
      <Col lg={8}>
        <ListGroup>
          {list.map((item, index) => (
            <ListGroup.Item key={index}>{item}</ListGroup.Item>
          ))}
        </ListGroup>
      </Col>
    </Row>
  </Container>
);

export default ServiceContainer;
