import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const ContainerWithIcon = ({ icon, iconSize, heading, button, children }) => (
  <Container>
    <Row className="d-flex justify-content-center mb-4">
      <Col md={8} lg={6} xl={5} className="text-center">
        <div className={`icon${iconSize ? `-${iconSize}` : ""} mb-4`}>
          {icon}
        </div>
        <h2>{heading}</h2>
        {children}
      </Col>
    </Row>
    <Row>
      <Col className="d-flex justify-content-center">{button}</Col>
    </Row>
  </Container>
);

export default ContainerWithIcon;
