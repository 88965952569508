import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import HtmlHeader from "../../utilities/HtmlHeader";
import Cover from "./Cover";
import ShopLinks from "./ShopLinks";
import ExpandableText from "../ExpandableText";
import Section from "../Section";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";

const DetailPage = ({ match }) => {
  useEffect(() => {
    const apiUrl =
      process.env.NODE_ENV === "production"
        ? "https://api.hannes-volk.de/audiobooks/"
        : "http://localhost:3001/audiobooks/";
    const fetchAudiobook = async (signal) => {
      const results = await fetch(apiUrl + match.params.id, {
        signal: signal,
      });
      const data = results.ok ? await results.json() : { error: true };
      setAudiobook(data);
    };
    const abortController = new AbortController();
    const signal = abortController.signal;
    fetchAudiobook(signal);
    return function cleanup() {
      abortController.abort();
    };
  }, [match.params.id]);

  const [audiobook, setAudiobook] = useState({});

  const [shopLinks, setShopLinks] = useState({});
  useEffect(() => {
    if (audiobook) {
      setShopLinks({
        audible_url: audiobook.audible_url,
        spotify_url: audiobook.spotify_url,
        thalia_url: audiobook.thalia_url,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audiobook]);

  return (
    <>
      {audiobook.title && audiobook.cover_url && audiobook.description ? (
        <HtmlHeader
          title={`${audiobook.title} Hörbuch`}
          image={audiobook.cover_url}
          description={audiobook.description}
        />
      ) : (
        <HtmlHeader title="Hörbuch" />
      )}
      <Section>
        <Container>
          {audiobook.error ? (
            <Alert variant="danger" className="mb-0">
              <Alert.Heading>
                Das gesuchte Hörbuch existiert nicht
              </Alert.Heading>
              <p>
                Das von Ihnen gesuchte Hörbuch existiert leider nicht. Dies kann
                unter anderem daran liegen, dass dieses Hörbuch entfernt wurde.
                Sie können sich eine Übersicht über alle zur Verfügung stehenden
                Hörbücher auf der{" "}
                <Alert.Link as={Link} to="/hörbücher">
                  Übersichtsseite
                </Alert.Link>{" "}
                ansehen.
              </p>
            </Alert>
          ) : (
            <>
              <Row>
                <Col lg={5} xl={6}>
                  <Cover
                    cover_url={audiobook.cover_url}
                    title={audiobook.title}
                  />
                </Col>
                <Col lg={7} xl={6} className="mt-5 mt-lg-0 d-flex flex-column">
                  <Row className="text-center text-lg-left">
                    <Col>
                      <h1 className="h2">
                        {audiobook.title ? audiobook.title : <Skeleton />}
                      </h1>
                      <p className="lead">
                        {audiobook.artist ? audiobook.artist : <Skeleton />}
                      </p>
                    </Col>
                  </Row>
                  <Row className="mt-4 mb-4 text-center">
                    <Col sm={4} className="mb-3 mb-sm-0">
                      <p className="font-weight-bold mb-0">
                        {audiobook.release_date ? (
                          "Veröffentlichung"
                        ) : (
                          <Skeleton />
                        )}
                      </p>
                      <p className="mb-0">
                        {audiobook.release_date ? (
                          new Date(audiobook.release_date).toLocaleDateString(
                            undefined,
                            {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                            }
                          )
                        ) : (
                          <Skeleton />
                        )}
                      </p>
                    </Col>
                    <Col sm={4} className="mb-3 mb-sm-0">
                      <p className="font-weight-bold mb-0">
                        {audiobook.tracks ? "Anzahl Titel" : <Skeleton />}
                      </p>
                      <p className="mb-0">
                        {audiobook.tracks ? (
                          audiobook.tracks.length
                        ) : (
                          <Skeleton />
                        )}
                      </p>
                    </Col>
                    <Col sm={4}>
                      <p className="font-weight-bold mb-0">
                        {audiobook.title ? "Format" : <Skeleton />}
                      </p>
                      <p className="mb-0">
                        {audiobook.title ? "Hörbuch" : <Skeleton />}
                      </p>
                    </Col>
                  </Row>
                  <Row className="mt-4 mt-lg-auto text-center">
                    <Col>
                      {audiobook.audible_url ? (
                        <ShopLinks
                          audiobookTitle={audiobook.title}
                          shopLinks={shopLinks}
                        />
                      ) : (
                        <Skeleton height={40} />
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-5 pt-5">
                <Col md={10} lg={8} xl={6} className="mx-auto">
                  <h3>
                    {audiobook.description ? "Beschreibung" : <Skeleton />}
                  </h3>
                  {audiobook.description ? (
                    <ExpandableText
                      text={audiobook.description}
                      maxCharacterCount={250}
                      expandable={true}
                    />
                  ) : (
                    <Skeleton count={5} />
                  )}
                </Col>
              </Row>
              <Row className="mt-5 pt-5">
                <Col>
                  {!audiobook.tracks ? (
                    <Skeleton height={50} count={8} />
                  ) : (
                    <Table striped bordered hover responsive>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Titel</th>
                          <th>Interpret</th>
                          <th>Spieldauer</th>
                        </tr>
                      </thead>
                      <tbody>
                        {audiobook.tracks.map((track) => (
                          <tr key={track.track_number}>
                            <td>{track.track_number}</td>
                            <td>{track.title}</td>
                            <td>{track.artist}</td>
                            <td>{track.play_time}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </Col>
              </Row>
            </>
          )}
        </Container>
      </Section>
    </>
  );
};

export default DetailPage;
