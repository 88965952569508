import React from "react";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import Atm from "../assets/references/atm.jpg";
import Daa from "../assets/references/daa.jpg";
import Dhsh from "../assets/references/dhsh.jpg";
import Elbcampus from "../assets/references/elbcampus.jpg";
import Fhm from "../assets/references/fhm.jpg";
import Grone from "../assets/references/grone.jpg";
import Hfh from "../assets/references/hfh.jpg";
import Hsw from "../assets/references/hsw.jpg";
import Hwk from "../assets/references/hwk.jpg";
import Ihk from "../assets/references/ihk.jpg";
import Ils from "../assets/references/ils.jpg";
import Saz from "../assets/references/saz.jpg";
import Sgd from "../assets/references/sgd.jpg";
import Wak from "../assets/references/wak.jpg";

const ReferenceSlider = () => {
  const references = [
    { name: "ATM", img: Atm, link: "https://www.atm.de" },
    { name: "DAA", img: Daa, link: "https://daa-mv.de/" },
    { name: "DHSH", img: Dhsh, link: "https://www.dhsh.de" },
    { name: "Elbcampus", img: Elbcampus, link: "https://www.elbcampus.de" },
    { name: "FHM", img: Fhm, link: "http://www.fh-mittelstand.de/schwerin/" },
    { name: "Grone", img: Grone, link: "https://www.grone.de/" },
    { name: "HFH", img: Hfh, link: "https://www.hfh-fernstudium.de/" },
    { name: "hsw", img: Hsw, link: "https://www.hs-wismar.de/" },
    { name: "HWK", img: Hwk, link: "https://www.hwk-schwerin.de/" },
    { name: "IHK", img: Ihk, link: "https://www.ihkzuschwerin.de/" },
    { name: "ils", img: Ils, link: "https://www.ils.de/" },
    { name: "SAZ", img: Saz, link: "https://www.sazev.de" },
    { name: "sgd", img: Sgd, link: "https://www.sgd.de" },
    { name: "WAK", img: Wak, link: "http://www.wak-sh.de/" },
  ];
  const slides = references.map((reference, index) => (
    <a
      key={index}
      style={{ position: "relative", height: "4em" }}
      href={reference.link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        className="d-block mx-auto"
        src={reference.img}
        alt={`${reference.name} - Logo`}
        style={{
          height: "100%",
        }}
      />
    </a>
  ));

  return (
    <Section>
      <Container>
        <Carousel
          slides={slides}
          slidesPerPage={5}
          infinite
          keepDirectionWhenDragging
          autoPlay={4000}
          animationSpeed={1000}
          stopAutoPlayOnHover
          breakpoints={{
            480: {
              slidesPerPage: 1,
            },
            576: {
              slidesPerPage: 2,
            },
            768: {
              slidesPerPage: 3,
            },
            992: {
              slidesPerPage: 4,
            },
          }}
        />
      </Container>
    </Section>
  );
};

export default ReferenceSlider;
