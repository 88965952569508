import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { CookieProvider } from "./contexts/CookieContext";
import Matomo from "./utilities/Matomo";
import Navigation from "./components/Nav";
import Footer from "./components/Footer";
import CookieAlert from "./components/CookieAlert";
import ScrollToTop from "./components/ScrollToTop";
import Home from "./Home";
import Webinar from "./Webinar";
import Audiobooks from "./Audiobooks";
import DetailPage from "./components/audiobooks/DetailPage";
import Services from "./Services";
import AboutMe from "./AboutMe";
import Contact from "./Contact";
import Imprint from "./Imprint";
import PrivacyPolicy from "./PrivacyPolicy";
import CookiePage from "./CookiePage";

function App() {
  new Matomo({
    url: "https://analytics.hannes-volk.de",
    siteId: 1,
    enableLinkTracking: true,
  });
  return (
    <Router>
      <CookieProvider>
        <ScrollToTop />
        <Navigation />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/webinar" exact component={Webinar} />
          <Route path="/hörbücher" exact component={Audiobooks} />
          <Route path="/hörbücher/:id" component={DetailPage} />
          <Route path="/leistungen" exact component={Services} />
          <Route path="/über-mich" exact component={AboutMe} />
          <Route path="/kontakt" exact component={Contact} />
          <Route path="/impressum" exact component={Imprint} />
          <Route path="/datenschutz" exact component={PrivacyPolicy} />
          <Route path="/cookies" exact component={CookiePage} />
          <Redirect to="/" />
        </Switch>
        <Footer />
        <CookieAlert />
      </CookieProvider>
    </Router>
  );
}

export default App;
