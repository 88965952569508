import React from "react";
import { Link } from "react-router-dom";
import Header from "./components/Header";
import Section from "./components/Section";
import ServiceContainer from "./components/ServiceContainer";
import ReferenceSlider from "./components/ReferenceSlider";
import ContainerWithIcon from "./components/ContainerWithIcon";
import ButtonMarketing from "./components/ButtonMarketing";
import HtmlHeader from "./utilities/HtmlHeader";
import BackgroundImage from "./assets/bg/cellphone-coffee-desk-860379.jpg";
import { Forum } from "@material-ui/icons/";

const Services = () => (
  <>
    <HtmlHeader title="Leistungen" />
    <Header title="Leistungen" image={BackgroundImage} />
    <Section variant="light">
      <ServiceContainer
        title="Beratung"
        description="Beratungen und Anwendungen in verschiedenen Unternehmensbereichen"
        list={[
          "Finanzierung und Investition",
          "Kostenrechnung und Kalkulation",
          "Finanzbuchhaltung und Jahresabschluss",
          "Jahresabschlussauswertungen / Erstellung und Interpretation von Jahresabschlusskennzahlen",
        ]}
      />
    </Section>
    <Section>
      <ServiceContainer
        title="Schulungen"
        description="Seminare und Webinare auch als Nachhilfe"
        list={[
          "Prüfungsvorbereitungsseminare für Fachwirte IHK",
          "Prüfungsvorbereitungsseminare für Betriebswirte und technische Betriebswirte IHK",
          "Prüfungsvorbereitungsseminare für Meisterschüler der Handwerkskammern",
          "Seminare in den Bereichen Rechnungswesen, Controlling, Finanzierung, Investition, Kostenrechnung, Volkswirtschaftslehre, Steuern und Finanzmathematik",
        ]}
      />
    </Section>
    <Section variant="light">
      <ContainerWithIcon
        icon={<Forum />}
        iconSize="sm"
        heading="Senden Sie Ihre Anfrage"
        button={
          <ButtonMarketing as={Link} to="/kontakt">
            Zum Kontaktformular
          </ButtonMarketing>
        }
      >
        <p>
          Sie interessieren sich für mein Angebot oder haben Fragen zu diesem?
          Senden sie mir gerne eine Nachricht über das Kontaktformular.
        </p>
      </ContainerWithIcon>
    </Section>
    <ReferenceSlider />
  </>
);

export default Services;
