import React from "react";
import Button from "react-bootstrap/Button";

const ButtonHeader = ({ children, ...rest }) => (
  <Button variant="outline-light" className="btn-autosize mt-lg-4" {...rest}>
    {children}
  </Button>
);

export default ButtonHeader;
