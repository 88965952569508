import React from "react";
import { Link } from "react-router-dom";
import Header from "./components/Header";
import HelpStep from "./components/HelpStep";
import Section from "./components/Section";
import ContainerWithIcon from "./components/ContainerWithIcon";
import ButtonWithIcon from "./components/ButtonWithIcon";
import HtmlHeader from "./utilities/HtmlHeader";
import BackgroundImage from "./assets/bg/ballpen-blur-close-up-461077.jpg";
import { ReactComponent as VideoCall } from "./assets/icons/video_call.svg";
import { GetApp, OpenInNew } from "@material-ui/icons";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import ButtonMarketing from "./components/ButtonMarketing";

const Webinar = () => {
  const webinarLink = "https://join.skype.com/invite/iU8qrNMh7d26";
  return (
    <>
      <HtmlHeader title="Webinar" />
      <Header title="Webinar" image={BackgroundImage} />
      <Section>
        <Container>
          <Row className="d-flex">
            <Col
              lg={5}
              className="d-flex justify-content-center align-items-center order-lg-2"
            >
              <VideoCall className="illustration mb-5 mb-lg-0" />
            </Col>
            <Col lg={7} className="order-lg-1">
              <h2>Unterricht am eigenen Rechner</h2>
              <p>
                Sie interessieren sich für ein Seminar von Johannes Volk? Was
                halten Sie von einem Webinar? Nehmen Sie ganz einfach an einem
                Webinar teil, indem Sie sich die Software für das Webinar hier
                downloaden. So erhalten Sie ein persönliches Seminar vom eigenen
                Schreibtisch aus.
              </p>
              <ButtonMarketing as={Link} to="/kontakt" className="mt-4">
                Jetzt Termin vereinbaren
              </ButtonMarketing>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section variant="light">
        <ContainerWithIcon
          icon={<GetApp />}
          heading="Softwaredownload"
          button={
            <a
              href={webinarLink}
              className="bst-button-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ButtonWithIcon variant="light" icon={<OpenInNew />}>
                Mit Skype verbinden
              </ButtonWithIcon>
            </a>
          }
        >
          <p>
            Laden Sie sich hier die Software herunter, die für die Webinare
            verwendet wird. Falls Sie Microsoft Edge oder Google Chrome als
            Browser verwenden, können Sie auch über Ihren Browser an Webinaren
            teilnehmen.
          </p>
        </ContainerWithIcon>
      </Section>
      <Section>
        <Container>
          <Row className="d-flex justify-content-center">
            <Col md={10} lg={8}>
              <h2>So geht's</h2>
              <p>
                Mit diesen Schritten können Sie sich auf ein Webinar
                vorbereiten.
              </p>
              <HelpStep number={1} heading="Skype Einladungslink öffnen">
                Öffnen Sie den{" "}
                <a href={webinarLink} target="_blank" rel="noopener noreferrer">
                  Einladungslink
                </a>
                , um sich mit meinem Skype Profil zu verknüpfen.
              </HelpStep>
              <HelpStep number={2} heading="Skype starten">
                Es öffnet sich ein neues Browser-Fenster, welches versucht,
                Skype auf Ihrem Computer zu öffnen. Falls Sie Skype noch nicht
                installiert haben, wird Ihnen die Möglichkeit angezeigt, Skype
                herunterzuladen. Es besteht auch die Option im Browser
                teilzunehemen, wenn Sie Microsoft Edge oder Google Chrome
                verwenden.
              </HelpStep>
              <HelpStep number={3} heading="Anmelden">
                Melden Sie sich nun in Skype mit Ihrem Microsoft Konto an oder
                erstellen Sie sich ein neues, kostenloses Konto.
              </HelpStep>
              <HelpStep number={4} heading="Nachricht senden">
                Damit ich Sie als Kontakt in Skype hinterlegen kann, senden Sie
                bitte eine kurze Nachricht an mich oder klicken Sie auf
                &bdquo;Sage hallo&ldquo;.
              </HelpStep>
              <HelpStep number={5} heading="Headset anschließen">
                Das Webinar kann nun beginnen. Schließen Sie zuvor ein Headset
                an und vergewissern Sie sich, dass in den Einstellungen von
                Skype unter &bdquo;Audio und Video&ldquo; die korrekten
                Eingabegeräte ausgewählt sind. Die Einstellungen können Sie
                durch die Tastenkombination{" "}
                <Badge variant="light" className="text-monospace">
                  STRG + ,
                </Badge>{" "}
                auf Windows bzw.{" "}
                <Badge variant="light" className="text-monospace">
                  &#8984; Command + ,
                </Badge>{" "}
                auf macOS oder durch Klicken auf Ihr Profilbild und im Folgenden
                auf Einstellungen öffnen.
              </HelpStep>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Webinar;
