import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { AlternateEmail, Call } from "@material-ui/icons/";
import { ReactComponent as Logo } from "../assets/logo/hannes-volk-logo.svg";

const Footer = () => {
  const links = [
    {
      name: "Webinar",
      route: "/webinar",
    },
    {
      name: "Hörbücher",
      route: "/hörbücher",
    },
    {
      name: "Leistungen",
      route: "/leistungen",
    },
    {
      name: "Über mich",
      route: "/über-mich",
    },
    {
      name: "Kontakt",
      route: "/kontakt",
    },
  ];
  const legalLinks = [
    {
      name: "Impressum",
      route: "/impressum",
    },
    {
      name: "Datenschutz",
      route: "/datenschutz",
    },
    {
      name: "Cookie Richtline",
      route: "/cookies",
    },
  ];
  return (
    <footer className="py-5">
      <Container className="text-center text-md-left">
        <Row>
          <Col md={6} className="mt-md-0 mt-3">
            <Logo />
            <h5 className="mb-4">Volkswirt | Berater | Dozent</h5>
            <p>
              Seminare in BWL, Rechnungswesen, Investition, Kalkulation,
              Finanzierung Controlling &amp; Optimierung auch für Ihr
              Unternehmen.
            </p>
            <div className="d-lg-flex">
              <Col lg={6} className="mb-3">
                <Call className="align-top mr-1" />
                <a href="tel:+491724504622">0172 / 450 46 22</a>
              </Col>
              <Col lg={6} className="mb-3">
                <AlternateEmail className="align-top mr-1" />
                <a href="mailto:info@hannes-volk.de">info@hannes-volk.de</a>
              </Col>
            </div>
          </Col>
          <Col md={3} className="mb-md-0 mb-3">
            <h5 className="mb-4">Links</h5>
            <ul className="list-unstyled">
              {links.map((link, index) => (
                <li key={index}>
                  <Button
                    as={Link}
                    to={link.route}
                    variant="link"
                    className="px-0"
                  >
                    {link.name}
                  </Button>
                </li>
              ))}
            </ul>
          </Col>
          <Col md={3} className="mb-md-0 mb-3">
            <h5 className="mb-4">Rechtliches</h5>
            <ul className="list-unstyled">
              {legalLinks.map((link, index) => (
                <li key={index}>
                  <Button
                    as={Link}
                    to={link.route}
                    variant="link"
                    className="px-0"
                  >
                    {link.name}
                  </Button>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
        <Row>
          <Col className="text-center pt-3">
            <small>&copy; {new Date().getFullYear()} Johannes Volk</small>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
