import React, { useEffect, useState } from "react";
import ListElement from "./components/audiobooks/ListElement";
import AudiobookPromotion from "./components/audiobooks/AudiobookPromotion";
import Header from "./components/Header";
import Section from "./components/Section";
import HtmlHeader from "./utilities/HtmlHeader";
import BackgroundImage from "./assets/bg/black-cordless-headphones-815494.jpg";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Audiobooks = () => {
  useEffect(() => {
    const apiUrl =
      process.env.NODE_ENV === "production"
        ? "https://api.hannes-volk.de/audiobooks/"
        : "http://localhost:3001/audiobooks/";
    const fetchAudiobooks = async (signal) => {
      const results = await fetch(apiUrl, {
        signal: signal,
      });
      const data = await results.json();
      setAudiobooks(data);
    };
    const abortController = new AbortController();
    const signal = abortController.signal;
    fetchAudiobooks(signal);
    return function cleanup() {
      abortController.abort();
    };
  }, []);

  const [audiobooks, setAudiobooks] = useState([{}, {}, {}]);

  return (
    <>
      <HtmlHeader title="Hörbücher" />
      <Header title="Hörbücher" image={BackgroundImage} />
      <AudiobookPromotion />
      <Section variant="light">
        <Container>
          <Row className="mb-4">
            <Col>
              <h2>Alle Hörbücher</h2>
            </Col>
          </Row>
          <Row>
            {audiobooks.map((audiobook, index) => (
              <ListElement key={audiobook.id || index} {...audiobook} />
            ))}
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Audiobooks;
