import React from "react";
import Button from "react-bootstrap/Button";

const ButtonWithIcon = ({
  icon,
  className,
  children,
  orientation,
  noPadding,
  ...rest
}) => {
  let buttonPadding = "pr-2";
  let spaceBetweenIconAndChildren = " ml-2";
  let orderIcon = "order-3";
  let orderChildren = "order-1";

  if (orientation === "left") {
    buttonPadding = "pl-2";
    orderIcon = "order-1";
    orderChildren = "order-3";
  }

  if (noPadding) {
    buttonPadding = "p-0";
    spaceBetweenIconAndChildren = "";
  }

  return (
    <Button
      className={`${buttonPadding} d-inline-flex align-items-center${
        className ? " " + className : ""
      }`}
      {...rest}
    >
      <div
        className={`${orderIcon} d-inline-flex align-items-center`}
        style={{ height: 24, width: 24 }}
      >
        {icon}
      </div>
      <div className={`order-2${spaceBetweenIconAndChildren}`}></div>
      <div className={`${orderChildren}`}>{children}</div>
    </Button>
  );
};
export default ButtonWithIcon;
