import React from "react";
import { Link } from "react-router-dom";
import CalculatorImage from "../assets/bg/accounting-black-budget-53621.jpg";
import PeopleImage from "../assets/bg/advice-advise-advisor-7096.jpg";
import CityImage from "../assets/bg/architecture-blue-sky-city-1124460.jpg";
import ButtonHeader from "./ButtonHeader";
import Carousel from "react-bootstrap/Carousel";

const HeaderLandingPage = () => (
  <header>
    <Carousel>
      <Carousel.Item style={{ backgroundImage: `url(${CalculatorImage})` }}>
        <div className="overlay"></div>
        <div className="carousel-flex-container">
          <Carousel.Caption>
            <h1>Schulungen</h1>
            <p className="lead">
              Ich biete Schulungen und Webinare unter anderem in den Bereichen
              Investition, Kalkulation und Finanzierung an.
            </p>
            <ButtonHeader as={Link} to="/kontakt">
              Jetzt Termin vereinbaren
            </ButtonHeader>
          </Carousel.Caption>
        </div>
      </Carousel.Item>
      <Carousel.Item style={{ backgroundImage: `url(${PeopleImage})` }}>
        <div className="overlay"></div>
        <div className="carousel-flex-container">
          <Carousel.Caption>
            <h1>Beratung</h1>
            <p className="lead">
              Professionelle Beratung für Ihr Unternehmen. Optimierung,
              Controlling und vieles mehr.
            </p>
            <ButtonHeader as={Link} to="/leistungen">
              Leistungen
            </ButtonHeader>
          </Carousel.Caption>
        </div>
      </Carousel.Item>
      <Carousel.Item style={{ backgroundImage: `url(${CityImage})` }}>
        <div className="overlay"></div>
        <div className="carousel-flex-container">
          <Carousel.Caption>
            <h1>Ökonomie</h1>
            <p className="lead">
              Meine Hauptgebiete sind Volks- und Betriebswirtschaftslehre,
              Investition, Finanzierung sowie Rechnungswesen und Controlling.
            </p>
            <ButtonHeader as={Link} to="/über-mich">
              Informationen über mich
            </ButtonHeader>
          </Carousel.Caption>
        </div>
      </Carousel.Item>
    </Carousel>
  </header>
);

export default HeaderLandingPage;
