import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "./components/Header";
import Section from "./components/Section";
import HtmlHeader from "./utilities/HtmlHeader";
import BackgroundImage from "./assets/bg/bar-blur-bulb-1123262.jpg";
import VolkImage from "./assets/img/Volk.png";
import Skeleton from "react-loading-skeleton";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const AboutMe = () => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const handleImageLoaded = () => {
    setImageLoaded(true);
  };
  return (
    <>
      <HtmlHeader title="Über mich" />
      <Header title="Über mich" image={BackgroundImage} />
      <Section>
        <Container>
          <Row className="d-flex align-items-center mb-5">
            <Col
              md={4}
              className="order-md-2 text-center text-md-right mb-3 mb-md-0"
            >
              <Image
                src={VolkImage}
                alt="Johannes Volk"
                roundedCircle
                style={{
                  maxHeight: 200,
                  maxWidth: 200,
                  display: imageLoaded ? "inline" : "none",
                }}
                onLoad={handleImageLoaded}
              />
              {!imageLoaded ? (
                <div className="d-inline" style={{ maxWidth: 200 }}>
                  <Skeleton height={200} width={200} circle={true} />
                </div>
              ) : null}
            </Col>
            <Col md={8} className="order-md-1 text-center text-md-left">
              <h2>Johannes Volk</h2>
              <p className="lead mb-0">Volkswirt | Berater | Dozent</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>Sehr geehrte Damen und Herren,</p>
              <p>
                ich möchte hier die Möglichkeit ergreifen, mich kurz
                vorzustellen. Mein Name ist Johannes Volk und ich bin Dozent für
                die Bereiche Volkswirtschaftslehre, Rechnungswesen, Finanzierung
                / Investition, Bilanzierung, Controlling sowie Finanzmathematik.
              </p>
              <p>
                Mein Studium zum Diplom-Volkswirt habe ich an der Universität in
                Köln im Jahre 1991 abgeschlossen.
              </p>
              <p>
                Seit nunmehr über zwanzig Jahren unterrichte ich in den oben
                genannten Fachbereichen bei verschiedenen Bildungsträgern,
                vorwiegend im Hamburger, Lübecker und Schweriner Raum. Die von
                mir unterrichteten Kurse begleite ich bis zur Prüfung bei den
                Industrie- und Handelskammern, wo ich seit vielen Jahren auch
                ehrenamtlich im Prüfungsausschuss tätig bin.
              </p>
              <p>
                Ich betreue auch Fernlehrgänge sehr gerne, da ich die Erfahrung
                gemacht habe, dass die Teilnehmer hoch motiviert sind und sehr
                fleißig arbeiten, obwohl viele die Doppelbelastung von Lehrgang
                und Beruf und/oder Familie zu bewältigen haben. Außerdem sehe
                ich für beide Seiten eine erhebliche Zeitersparnis, da man von
                zu Hause aus arbeiten kann.
              </p>
              <p>
                Sollten Sie bezüglich meiner Angebote Fragen haben, können Sie
                mich telefonisch erreichen oder über das{" "}
                <Link to="/kontakt">Kontaktformular</Link> auf meiner
                Internetseite. Ich werde Ihre Fragen so schnell wie möglich
                beantworten.
              </p>
              <p>
                Vielen Dank für Ihre Aufmerksamkeit sowie viel Spaß und Erfolg
                bei Ihrem Lehrgang wünscht Ihnen
              </p>
              <p className="mb-0">Diplom-Volkswirt</p>
              <p className="mb-0">Johannes Volk</p>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default AboutMe;
