import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const HelpStep = ({ number, heading, children }) => (
  <div className="bst-border-bottom pt-2 pb-4">
    <Row>
      <Col xs={1} className="display-3 text-primary">
        {number}
      </Col>
      <Col xs={11} className="mt-1 mt-lg-2 mt-xl-3">
        <h3>{heading}</h3>
        {children}
      </Col>
    </Row>
  </div>
);

export default HelpStep;
