import React from "react";
import { KeyboardArrowRight } from "@material-ui/icons/";
import ButtonWithIcon from "./ButtonWithIcon";

const ButtonLink = ({ className, children, ...rest }) => (
  <ButtonWithIcon
    icon={<KeyboardArrowRight />}
    variant="link"
    noPadding={true}
    className={className}
    {...rest}
  >
    {children}
  </ButtonWithIcon>
);

export default ButtonLink;
