import React, { useState } from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons/";
import ButtonWithIcon from "./ButtonWithIcon";

const ExpandableText = ({ text, maxCharacterCount, expandable, className }) => {
  const [showMore, setShowMore] = useState(false);
  const toggleShowMore = () => {
    setShowMore(!showMore);
  };
  const Text = ({ shortenText }) => {
    if (shortenText && !showMore) {
      return text.slice(0, maxCharacterCount) + "...";
    } else {
      return text;
    }
  };
  return (
    <>
      <p className={className}>
        <Text shortenText={text.length > maxCharacterCount} />
      </p>
      {expandable && text.length > maxCharacterCount ? (
        <div className="d-flex justify-content-center">
          <ButtonWithIcon
            icon={showMore ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            variant="link"
            onClick={toggleShowMore}
          >
            {showMore ? "Weniger anzeigen" : "Mehr anzeigen"}
          </ButtonWithIcon>
        </div>
      ) : null}
    </>
  );
};

export default ExpandableText;
