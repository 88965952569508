import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import ButtonLink from "../ButtonLink";
import Cover from "./Cover";
import ShopLinks from "./ShopLinks";
import ExpandableText from "../ExpandableText";
import Section from "../Section";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";

const AudiobookPromotion = () => {
  useEffect(() => {
    const apiUrl =
      process.env.NODE_ENV === "production"
        ? "https://api.hannes-volk.de/audiobooks"
        : "http://localhost:3001/audiobooks";
    const fetchAudiobook = async (signal) => {
      const results = await fetch(apiUrl + "?advertised=1", {
        signal: signal,
      });
      const data = results.ok ? await results.json() : { error: true };
      setAudiobook(data[0]);
    };
    const abortController = new AbortController();
    const signal = abortController.signal;
    fetchAudiobook(signal);
    return function cleanup() {
      abortController.abort();
    };
  }, []);

  const [audiobook, setAudiobook] = useState({});

  const [shopLinks, setShopLinks] = useState({});
  useEffect(() => {
    if (audiobook) {
      setShopLinks({
        audible_url: audiobook.audible_url,
        spotify_url: audiobook.spotify_url,
        thalia_url: audiobook.thalia_url,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audiobook]);

  return audiobook ? (
    <Section>
      <Container>
        <Row>
          <Col lg={5} xl={6}>
            <Cover
              cover_url={audiobook.cover_url}
              title={audiobook.title}
              linkTo={`/hörbücher/${audiobook.id}`}
            />
          </Col>
          <Col lg={7} xl={6} className="mt-5 mt-lg-0 d-flex flex-column">
            <Row className="text-center text-lg-left">
              <Col>
                {audiobook.title ? (
                  <Badge variant="primary" className="mb-1">
                    Hörbuch
                  </Badge>
                ) : (
                  <Skeleton height={18} />
                )}
                <h2>{audiobook.title ? audiobook.title : <Skeleton />}</h2>
                <p className="lead">
                  {audiobook.artist ? audiobook.artist : <Skeleton />}
                </p>
              </Col>
            </Row>
            <Row className="mt-4 mb-4">
              <Col>
                {audiobook.description ? (
                  <ExpandableText
                    text={audiobook.description}
                    maxCharacterCount={250}
                  />
                ) : (
                  <Skeleton count={4} />
                )}
              </Col>
            </Row>
            <Row className="mt-4 mt-lg-auto">
              {audiobook.id && audiobook.audible_url ? (
                <Col className="d-flex flex-column flex-sm-row align-items-center justify-content-between">
                  <ShopLinks
                    audiobookTitle={audiobook.title}
                    shopLinks={shopLinks}
                    className="mb-4 mb-sm-0"
                  />
                  <ButtonLink as={Link} to={`/hörbücher/${audiobook.id}`}>
                    Mehr Informationen
                  </ButtonLink>
                </Col>
              ) : (
                <Col>
                  <Skeleton height={31} />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Container>
    </Section>
  ) : null;
};

export default AudiobookPromotion;
