import React from "react";
import ButtonWithIcon from "./ButtonWithIcon";
import { KeyboardArrowRight } from "@material-ui/icons/";

const ButtonMarketing = ({ children, ...rest }) => (
  <ButtonWithIcon variant="light" icon={<KeyboardArrowRight />} {...rest}>
    {children}
  </ButtonWithIcon>
);

export default ButtonMarketing;
