import React from "react";

const Section = ({ children, variant, border, className, ...rest }) => {
  let classNameValue = "bst-section";
  if (variant) {
    let variantOptions = variant.split(" ");
    variantOptions = variantOptions.map(
      (variantOption) => `bst-section-${variantOption}`
    );
    classNameValue += " " + variantOptions.join(" ");
  }
  if (border) {
    let borderOptions = border.split(" ");
    borderOptions = borderOptions.map(
      (borderOption) => `bst-border-${borderOption}`
    );
    classNameValue += " " + borderOptions.join(" ");
  }
  if (className) classNameValue += " " + className;
  return (
    <section className={classNameValue} {...rest}>
      {children}
    </section>
  );
};

export default Section;
