import React from "react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import Cover from "./Cover";
import ShopLinks from "./ShopLinks";
import ExpandableText from "../ExpandableText";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";

const Audiobooks = ({
  id,
  title,
  description,
  cover_url,
  audible_url,
  spotify_url,
  thalia_url,
}) => {
  const detailRoute = `/hörbücher/${id}`;

  let shopLinks = {
    audible_url: audible_url,
    spotify_url: spotify_url,
    thalia_url: thalia_url,
  };

  return (
    <Col md={6} xl={4} className="mb-3 mx-auto">
      <Card className="h-100">
        <Card.Body>
          <Cover
            cover_url={cover_url}
            title={title}
            skeletonHeight={303}
            linkTo={detailRoute}
          />
          <Card.Title className="mt-4">{title || <Skeleton />}</Card.Title>
          {description ? (
            <ExpandableText
              className="mb-0"
              text={description}
              maxCharacterCount={200}
            />
          ) : (
            <Skeleton count={6} />
          )}
        </Card.Body>

        <Card.Footer>
          <Row>
            <Col xs={8}>
              {id ? (
                <Button
                  variant="link"
                  as={Link}
                  to={detailRoute}
                  className="px-0"
                >
                  Mehr Informationen
                </Button>
              ) : (
                <Skeleton height={31} />
              )}
            </Col>
            <Col xs={4}>
              {audible_url ? (
                <ShopLinks
                  variant="dropdown"
                  audiobookTitle={title}
                  shopLinks={shopLinks}
                  className="d-flex justify-content-end"
                />
              ) : (
                <Skeleton height={31} />
              )}
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </Col>
  );
};

export default Audiobooks;
