import React, { useState } from "react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import Image from "react-bootstrap/Image";

const Cover = ({ cover_url, title, skeletonHeight, linkTo }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const handleImageLoaded = () => {
    setImageLoaded(true);
  };
  if (!skeletonHeight) skeletonHeight = 320;
  const CoverSkeleton = () => (
    <div className="d-block mx-auto" style={{ maxWidth: 320 }}>
      <Skeleton height={skeletonHeight} />
    </div>
  );
  return (
    <>
      {cover_url && linkTo ? (
        <Link to={linkTo}>
          <Image
            src={cover_url}
            alt={title + " - Cover"}
            rounded
            className="w-100 mx-auto"
            style={{
              maxHeight: 320,
              maxWidth: 320,
              display: imageLoaded ? "block" : "none",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
            onLoad={handleImageLoaded}
          />
        </Link>
      ) : null}
      {cover_url && !linkTo ? (
        <Image
          src={cover_url}
          alt={title + " - Cover"}
          rounded
          className="w-100 mx-auto"
          style={{
            maxHeight: 320,
            maxWidth: 320,
            display: imageLoaded ? "block" : "none",
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          }}
          onLoad={handleImageLoaded}
        />
      ) : null}
      {!cover_url || !imageLoaded ? <CoverSkeleton /> : null}
    </>
  );
};

export default Cover;
