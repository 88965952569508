import React, { useContext, useRef } from "react";
import Header from "./components/Header";
import Section from "./components/Section";
import HtmlHeader from "./utilities/HtmlHeader";
import BackgroundImage from "./assets/bg/clipboard-close-up-composition-893894.jpg";
import { CookieContext } from "./contexts/CookieContext";
import Cookies from "js-cookie";
import Container from "react-bootstrap/Container";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";

const CookiePage = () => {
  const [cookies, setCookies] = useContext(CookieContext);
  const functionalSwitch = useRef(null);
  const performanceSwitch = useRef(null);
  const marketingSwitch = useRef(null);

  const saveCookies = (value) => {
    Cookies.set("ckie_functional", value.functional, { expires: 365 });
    Cookies.set("ckie_performance", value.performance, { expires: 365 });
    Cookies.set("ckie_marketing", value.marketing, { expires: 365 });
  };

  const setCookieContext = (cookieSettings) => {
    setCookies(cookieSettings);
  };

  const updateCookieSettings = () => {
    const cookieValue = {
      functional: functionalSwitch.current.checked,
      performance: performanceSwitch.current.checked,
      marketing: marketingSwitch.current.checked,
    };
    saveCookies(cookieValue);
    setCookieContext(cookieValue);
  };

  const CookieTable = ({ cookieInformation }) => (
    <Table responsive>
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Zweck</th>
          <th scope="col">Laufzeit</th>
        </tr>
      </thead>
      <tbody>
        {cookieInformation.map((cookieInfo, index) => (
          <tr key={index}>
            <td>{cookieInfo.name}</td>
            <td>{cookieInfo.description}</td>
            <td>{cookieInfo.duration}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );

  return (
    <>
      <HtmlHeader title="Cookie Richtlinie" />
      <Header title="Cookie Richtlinie" image={BackgroundImage} />
      <Section>
        <Container>
          <p>
            Mit dieser Cookie Richtlinie informiert der Website-Betreiber den
            Nutzer der Website über den Einsatz von Cookies oder ähnlichen
            Speichertechnologien (nachfolgend „Cookies“) auf dieser Website.
          </p>

          <h3>Was sind Cookies?</h3>
          <p>
            Cookies sind kleine Textdateien, die vom Webbrowser auf dem Endgerät
            des Nutzers zur Speicherung von bestimmten Informationen abgelegt
            werden. Beim nächsten Aufruf unserer Website mit demselben Endgerät
            werden die in Cookies gespeicherten Informationen an unsere Website
            („First Party Cookie“) oder eine andere Website, zu der das Cookie
            gehört („Third Party Cookie“), zurückgesandt.
          </p>
          <p>
            Durch die im Cookie gespeicherten und zurückgesandten Informationen
            erkennt die jeweilige Website, dass der Nutzer diese mit dem
            Webbrowser seines Endgeräts bereits aufgerufen und besucht hat.
            Diese Informationen nutzen wir, um dem Nutzer die Website gemäß
            seinen Präferenzen optimal anzeigen zu können.
          </p>
          <p>
            Es wird hierbei aber nur das Cookie selbst auf dem Endgerät
            identifiziert. Eine darüber hinausgehende Speicherung von
            personenbezogenen Daten erfolgt nur, wenn der Nutzer uns seine
            ausdrückliche Zustimmung dafür gibt oder wenn diese Speicherung
            unbedingt erforderlich ist, um den angebotenen und aufgerufenen
            Dienst nutzen zu können.
          </p>

          <h3>Zustimmung zur Verwendung von Cookies</h3>
          <p>
            Cookies, die nicht unbedingt erforderlich sind, um die Dienste auf
            dieser Website zur Verfügung zu stellen, werden erst nach der
            Einwilligung verwendet. Indem der Nutzer diese Website nach dem
            Einblenden des Cookie-Banners aktiv weiter nutzt, willigt er in die
            Verwendung von Cookies ein.
          </p>
          <p>
            Seine Cookie Einstellungen kann der Nutzer jederzeit individuell
            anpassen, indem er zum Beispiel einzelne Cookie-Kategorien aktiviert
            oder deaktiviert.
          </p>
          <p>
            Wie der Nutzer Cookies generell (inklusive der unbedingt
            erforderlichen Cookies) in seinem Browser deaktivieren oder löschen
            kann, wird unter dem Punkt "Deaktivierung oder Löschung sämtlicher
            Cookies" beschrieben.
          </p>

          <h3>Kategorien der verwendeten Cookies</h3>
          <p>
            Je nach Verwendungszweck und Funktion unterteilen wir die von der
            Website verwendeten Cookies in die folgenden Kategorien:
          </p>
          <Accordion defaultActiveKey="0">
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                style={{ cursor: "pointer" }}
                className="d-flex justify-content-between"
                eventKey="0"
              >
                <b>Unbedingt erforderliche Cookies</b>
                <Form.Check
                  type="switch"
                  id="necessary-cookies-switch"
                  disabled
                  checked={true}
                  label=""
                  aria-label="Unbedingt erforderliche Cookies Einstellung"
                />
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p>
                    Unbedingt erforderliche Cookies sorgen dafür, dass diese
                    Website, beziehungsweise der Dienst, ordnungsgemäß
                    funktioniert. Das heißt, ohne diese Cookies ist diese
                    Website, beziehungsweise der Dienst, nicht wie vorgesehen
                    nutzbar. Diese Art von Cookies wird ausschließlich von dem
                    Betreiber der Website verwendet (First Party Cookies) und
                    sämtliche Informationen, die in den Cookies gespeichert
                    sind, werden nur an diese Website gesendet.
                  </p>
                  <p>
                    Auf unserer Webseite kommen folgende unbedingt
                    erforderlichen Cookies zum Einsatz:
                  </p>
                  <CookieTable
                    cookieInformation={[
                      {
                        name: "ckie_functional",
                        description: "Einstellung der Functional Cookies",
                        duration: "1 Jahr",
                      },
                      {
                        name: "ckie_performance",
                        description: "Einstellung der Performance Cookies",
                        duration: "1 Jahr",
                      },
                      {
                        name: "ckie_marketing",
                        description:
                          "Einstellung der Marketing-/Third Party-/Zustimmungspflichtigen-Cookies",
                        duration: "1 Jahr",
                      },
                      {
                        name: "contact_form_session",
                        description:
                          "Speichern der Captcha-Zeichenfolge für die Abwehr von Robotern",
                        duration: "Session",
                      },
                    ]}
                  />
                  <p>
                    Die Nutzung von unbedingt erforderlichen Cookies auf dieser
                    Website ist ohne die Einwilligung des Nutzers möglich. Aus
                    diesem Grund können unbedingt erforderliche Cookies auch
                    nicht einzeln deaktiviert bzw. aktiviert werden. Diese
                    Funktion steht nur bei einwilligungspflichtigen Cookies,
                    also den Funktionalen Cookies, den Performance-Cookies und
                    den Marketing- / Third Party- /
                    Zustimmungspflichtige-Cookies, zur Verfügung. Der Nutzer hat
                    allerdings jederzeit die Möglichkeit, Cookies generell in
                    seinem Browser zu deaktivieren (siehe Punkt "Deaktivierung
                    oder Löschung sämtlicher Cookies").
                  </p>
                  <p>
                    Die Rechtsgrundlage für die Nutzung der First Party Cookies
                    ist ein berechtigtes Interesse (d. h. Interesse an der
                    Analyse, Optimierung und dem wirtschaftlichen Betrieb dieser
                    Website und Leistungen) im Sinne des Art. 6 Abs. 1 S. 1 lit.
                    f DSGVO.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                style={{ cursor: "pointer" }}
                className="d-flex justify-content-between"
                eventKey="1"
              >
                <b>Funktionale-Cookies</b>
                <Form.Check
                  type="switch"
                  id="functional-coockies-switch"
                  ref={functionalSwitch}
                  checked={cookies.functional}
                  onChange={updateCookieSettings}
                  label=""
                  aria-label="Funktionale Cookies Einstellung"
                />
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <p>
                    Funktionale Cookies ermöglichen dieser Webseite, getätigte
                    Angaben, wie z. B. den Benutzernamen oder die Sprachauswahl,
                    zu speichern und dem Nutzer darauf basierend verbesserte und
                    personalisierte Funktionen anzubieten. Diese Cookies sammeln
                    und speichern ausschließlich anonymisierte Informationen.
                  </p>
                  <p>
                    Auf unserer Webseite kommen folgende Funktionale Cookies zum
                    Einsatz:
                  </p>
                  <CookieTable
                    cookieInformation={[
                      {
                        name: "/",
                        description: "/",
                        duration: "/",
                      },
                    ]}
                  />
                  <p>
                    Der Nutzer kann der Verwendung von Funktionalen Cookies
                    jederzeit widersprechen, indem er seine Cookie Einstellungen
                    entsprechend anpasst.
                  </p>
                  <p>
                    Die Rechtsgrundlage für die Nutzung der Funktionalen-Cookies
                    ist ein berechtigtes Interesse (d. h. Interesse an der
                    Analyse, Optimierung und dem wirtschaftlichen Betrieb dieser
                    Website und Leistungen) im Sinne des Art. 6 Abs. 1 S. 1 lit.
                    f DSGVO.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                style={{ cursor: "pointer" }}
                className="d-flex justify-content-between"
                eventKey="2"
              >
                <b>Performance-Cookies</b>
                <Form.Check
                  type="switch"
                  id="performance-coockies-switch"
                  ref={performanceSwitch}
                  checked={cookies.performance}
                  onChange={updateCookieSettings}
                  label=""
                  aria-label="Funktionale Cookies Einstellung"
                />
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <p>
                    Performance Cookies sammeln Informationen darüber, wie
                    unsere Webseiten genutzt werden, um darauf basierend deren
                    Attraktivität, Inhalt und Funktionalität zu verbessern.
                    Diese Cookies helfen uns beispielsweise festzustellen, ob
                    und welche Unterseiten unserer Webseite besucht werden und
                    für welche Inhalte sich die Nutzer besonders interessieren.
                    Wir erfassen die Anzahl der Zugriffe auf eine Seite, die
                    Anzahl der aufgerufenen Unterseiten, die auf unserer
                    Webseite verbrachte Zeit, die Reihenfolge der besuchten
                    Seiten, welche Suchbegriffe unsere Nutzer zu uns geführt
                    haben, das Land, die Region und gegebenenfalls die Stadt aus
                    der der Zugriff erfolgt, sowie den Anteil von mobilen
                    Endgeräten, die auf unsere Webseiten zugreifen. Die aus
                    technischen Gründen übermittelte IP-Adresse eines Rechners
                    wird automatisch anonymisiert und ermöglicht uns keinen
                    Rückschluss auf den einzelnen Nutzer.
                  </p>
                  <p>
                    Der Nutzer der Website hat auch das Recht, aus Gründen, die
                    sich aus seiner besonderen Situation ergeben, bei der
                    Verarbeitung ihn betreffender personenbezogener Daten, die
                    zu statistischen Zwecken gem. Art. 89 Abs. 1 DSGVO erfolgt,
                    zu widersprechen. Zur Wahrnehmung dieser und anderer
                    Betroffenenrechte, kann sich ein Betroffener jederzeit an
                    die im Impressum beziehungsweise in der Datenschutzerklärung
                    angegebene Kontaktadresse wenden.
                  </p>
                  <p>
                    Auf unserer Webseite kommen folgende Performance Cookies zum
                    Einsatz:
                  </p>
                  <CookieTable
                    cookieInformation={[
                      {
                        name: "_pk_id#",
                        description:
                          "Erkennt Website-Besucher (anonym – es werden keine personenbezogenen Nutzerdaten gespeichert)",
                        duration: "13 Monate",
                      },
                      {
                        name: "_pk_ref#",
                        description:
                          "Speichert Kampagneninformationen (anonym – es werden keine personenbezogenen Nutzerdaten gespeichert).",
                        duration: "6 Monate",
                      },
                      {
                        name: "_pk_ses#",
                        description:
                          "Erkennt, welche Seiten derselbe Nutzer während desselben Besuchs angezeigt hat. (anonym – es werden keine personenbezogenen Nutzerdaten gespeichert).",
                        duration: "30 Minuten",
                      },
                    ]}
                  />
                  <p>
                    Der Nutzer der Website kann der Verwendung von
                    Performance-Cookies jederzeit widersprechen, indem er seine
                    Cookie Einstellungen entsprechend anpasst.
                  </p>
                  <p>
                    Die Rechtsgrundlage für die Nutzung der Performance-Cookies
                    ist ein berechtigtes Interesse (d. h. Interesse an der
                    Analyse, Optimierung und dem wirtschaftlichen Betrieb
                    unserer Webseite und Leistungen) im Sinne des Art. 6 Abs. 1
                    S. 1 lit. f DSGVO.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                style={{ cursor: "pointer" }}
                className="d-flex justify-content-between"
                eventKey="3"
              >
                <b>Marketing- / Third Party- / Zustimmungspflichtige-Cookies</b>
                <Form.Check
                  type="switch"
                  id="marketing-coockies-switch"
                  ref={marketingSwitch}
                  checked={cookies.marketing}
                  onChange={updateCookieSettings}
                  label=""
                  aria-label="Funktionale Cookies Einstellung"
                />
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <p>
                    Marketing- / Third Party- / Zustimmungspflichtige-Cookies
                    stammen unter anderem von externen Werbeunternehmen und
                    werden verwendet, um Informationen über die vom Nutzer
                    besuchten Websites zu sammeln, um z. B.
                    zielgruppenorientierte Werbung für den Benutzer zu
                    erstellen.
                  </p>
                  <p>
                    Auf unserer Webseite kommen folgende Marketing- / Third
                    Party- / Zustimmungspflichtige-Cookies zum Einsatz:
                  </p>
                  <CookieTable
                    cookieInformation={[
                      {
                        name: "/",
                        description: "/",
                        duration: "/",
                      },
                    ]}
                  />
                  <p>
                    Der Nutzer kann der Verwendung von Marketing-Cookies
                    jederzeit widersprechen, indem er seine Cookie Einstellungen
                    entsprechend anpasst.
                  </p>
                  <p>
                    Aufgrund der oben beschriebenen Verwendungszwecke liegt die
                    Rechtsgrundlage für die Verarbeitung personenbezogener Daten
                    unter Verwendung von Cookies in Art. 6 Abs. 1 lit. f DSGVO.
                    Falls der Nutzer auf Grundlage eines von uns auf der Website
                    erteilten Hinweises („Cookie-Banner“) eine Einwilligung zur
                    Verwendung von Cookies erteilt hat, richtet sich die
                    Rechtmäßigkeit der Verwendung zusätzlich nach Art. 6 Abs. 1
                    S. 1 lit. a DSGVO.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <h3 className="mt-4">
            Deaktivierung oder Löschung sämtlicher Cookies
          </h3>
          <p>
            Der Nutzer kann seinen Webbrowser so einstellen, dass das Speichern
            von Cookies auf seinem Endgerät generell verhindert wird
            beziehungsweise er jedes Mal gefragt wird, ob er mit dem Setzen von
            Cookies einverstanden ist. Einmal gesetzte Cookies kann der Nutzer
            jederzeit wieder löschen. Wie das Ganze funktioniert, wird in
            Hilfe-Funktion des jeweiligen Webbrowsers beschrieben.
          </p>
          <p>
            Eine generelle Deaktivierung von Cookies kann gegebenenfalls zu
            Funktionseinschränkungen dieser Website führen.
          </p>
        </Container>
      </Section>
    </>
  );
};

export default CookiePage;
