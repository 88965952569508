import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "./components/Header";
import ButtonWithIcon from "./components/ButtonWithIcon";
import ButtonMarketing from "./components/ButtonMarketing";
import TextWithIcon from "./components/TextWithIcon";
import Section from "./components/Section";
import HtmlHeader from "./utilities/HtmlHeader";
import BackgroundImage from "./assets/bg/blur-book-stack-books-590493.jpg";
import { ReactComponent as MessageSent } from "./assets/icons/message_sent.svg";
import Skeleton from "react-loading-skeleton";
import {
  Send,
  Refresh,
  Phone,
  Home,
  AlternateEmail,
} from "@material-ui/icons/";
import { useFormik } from "formik";
import * as yup from "yup";
import yupLocaleDe from "./utilities/yupLocaleDe";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const Contact = () => {
  const [isSending, setSending] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [captchaRefreshing, setCaptchaRefreshing] = useState(false);
  const [captcha, setCaptcha] = useState(null);

  const apiUrl =
    process.env.NODE_ENV === "production"
      ? "https://api.hannes-volk.de/contact/"
      : "http://localhost:3001/contact/";

  const fetchCaptcha = (signal) => {
    return new Promise(async (resolve) => {
      const result = await fetch(apiUrl, {
        credentials: "include",
        signal: signal,
      });
      const data = await result.text();
      setCaptcha(data);
      const captchaContainer = document.querySelector("#captchaContainer");
      if (captchaContainer != null) {
        captchaContainer.innerHTML = data;
      }
      resolve();
    });
  };

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    fetchCaptcha(signal);
    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [captchaRefreshing]);

  const sendContactForm = (values) => {
    return new Promise(async (resolve) => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify(values),
      };
      const result = await fetch(apiUrl, requestOptions);
      const data = await result.json();
      if (data.error) {
        setShowSuccessMessage(false);
        setErrorMessage(data.error);
        setShowErrorMessage(true);
        setCaptchaRefreshing(!captchaRefreshing);
        formik.setFieldValue("captcha", "");
        formik.setFieldTouched("captcha", false);
        formik.setFieldValue("privacyPolicy", false);
        formik.setFieldTouched("privacyPolicy", false);
      } else {
        setErrorMessage("");
        setShowErrorMessage(false);
        setShowSuccessMessage(true);
        setCaptchaRefreshing(!captchaRefreshing);
        formik.handleReset();
      }
      resolve();
    });
  };

  yup.setLocale(yupLocaleDe);
  const schema = yup.object().shape({
    company: yup.string().label("Firma"),
    name: yup.string().required().label("Name"),
    mail: yup.string().email().required().label("E-Mail"),
    phone: yup.string().min(4).label("Telefon"),
    message: yup.string().required().label("Nachricht"),
    captcha: yup.string().min(4).max(4).required().label("Captcha"),
    privacyPolicy: yup
      .boolean()
      .oneOf([true], "Sie müssen der Datenschutzerklärung zustimmen"),
  });

  const formik = useFormik({
    validationSchema: schema,
    onSubmit: (values) => {
      setSending(true);
      sendContactForm(values).then(() => {
        setSending(false);
      });
    },
    initialValues: {
      company: "",
      name: "",
      mail: "",
      phone: "",
      message: "",
      captcha: "",
      privacyPolicy: false,
    },
  });

  return (
    <>
      <HtmlHeader title="Kontakt" />
      <Header title="Kontakt" image={BackgroundImage} />
      <Section>
        <Container>
          {!showSuccessMessage && <h2 className="mb-4">Kontaktformular</h2>}
          <Modal
            show={showErrorMessage}
            onHide={() => setShowErrorMessage(false)}
            aria-labelledby="error-modal-title"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="error-modal-title">
                Es ist ein Fehler aufgetreten
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className={errorMessage === "" ? "mb-0" : ""}>
                Beim Absenden des Kontaktformulars ist ein Fehler aufgetreten.
                Bitte versuchen Sie es erneut.
              </p>
              {errorMessage && <p className="mb-0">{errorMessage}</p>}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="danger"
                onClick={() => setShowErrorMessage(false)}
              >
                Schließen
              </Button>
            </Modal.Footer>
          </Modal>
          {showSuccessMessage && (
            <Row className="d-flex justify-content-center my-3">
              <Col sm={11} md={10} lg={9} xl={8}>
                <Card>
                  <Card.Body className="d-flex flex-column justify-content-center">
                    <MessageSent className="illustration mb-5" />
                    <div className="text-center mt-2 mb-4">
                      <h3>Vielen Dank für Ihre Nachricht.</h3>
                      <p>
                        Ihre Nachricht ist erfolgreich bei mir eingegangen. Ich
                        werde mich so schnell wie möglich um Ihr Anliegen
                        kümmern und mich bei Ihnen melden.
                      </p>
                    </div>
                    <div className="d-flex justify-content-center">
                      <ButtonMarketing as={Link} to="/">
                        Zur Startseite
                      </ButtonMarketing>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
          {/* <Alert
              variant="success"
              onClose={() => setShowSuccessMessage(false)}
              dismissible
            >
              <Alert.Heading>Vielen Dank für Ihre Nachricht.</Alert.Heading>
              <p className="mb-0">
                Ihre Nachricht ist erfolgreich bei mir eingegangen. Ich werde
                mich so schnell wie möglich um Ihr Anliegen kümmern und mich bei
                Ihnen melden.
              </p>
            </Alert> */}
          {!showSuccessMessage && (
            <Form noValidate onSubmit={formik.handleSubmit}>
              <Form.Group as={Row} controlId="formCompany">
                <Form.Label column md="2">
                  Firma
                </Form.Label>
                <Col md={10}>
                  <Form.Control
                    type="text"
                    name="company"
                    placeholder="Firma"
                    value={formik.values.company}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isValid={formik.touched.company && !formik.errors.company}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formName">
                <Form.Label column md="2">
                  Name*
                </Form.Label>
                <Col md={10}>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isValid={formik.touched.name && !formik.errors.name}
                    isInvalid={formik.touched.name && !!formik.errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formMail">
                <Form.Label column md="2">
                  E-Mail*
                </Form.Label>
                <Col md={10}>
                  <Form.Control
                    type="email"
                    name="mail"
                    placeholder="E-Mail-Adresse"
                    value={formik.values.mail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isValid={formik.touched.mail && !formik.errors.mail}
                    isInvalid={formik.touched.mail && !!formik.errors.mail}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.mail}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formPhone">
                <Form.Label column md="2">
                  Telefon
                </Form.Label>
                <Col md={10}>
                  <Form.Control
                    type="text"
                    name="phone"
                    placeholder="Telefonnummer"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isValid={formik.touched.phone && !formik.errors.phone}
                    isInvalid={formik.touched.phone && !!formik.errors.phone}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.phone}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formMessage">
                <Form.Label column md="2">
                  Nachricht*
                </Form.Label>
                <Col md={10}>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    name="message"
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isValid={formik.touched.message && !formik.errors.message}
                    isInvalid={
                      formik.touched.message && !!formik.errors.message
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.message}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formCaptcha">
                <Form.Label column md="2">
                  Captcha*
                </Form.Label>
                <Col md={10}>
                  <div className="d-flex flex-column flex-sm-row justify-content-start align-items-center mb-3">
                    {captcha ? (
                      <div id="captchaContainer"></div>
                    ) : (
                      <Skeleton height={50} width={150} />
                    )}
                    <ButtonWithIcon
                      variant="link"
                      icon={<Refresh />}
                      onClick={() => setCaptchaRefreshing(!captchaRefreshing)}
                      orientation="left"
                      className="mt-2 mt-sm-0"
                    >
                      Neu generieren
                    </ButtonWithIcon>
                  </div>
                  <Form.Control
                    type="text"
                    name="captcha"
                    placeholder="Bitte geben Sie die Zeichenfolge hier ein."
                    value={formik.values.captcha}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isValid={formik.touched.captcha && !formik.errors.captcha}
                    isInvalid={
                      formik.touched.captcha && !!formik.errors.captcha
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.captcha}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formPrivacyPolicy">
                <Form.Label column md="2">
                  Datenschutz*
                </Form.Label>
                <Col md={10} className="mt-2">
                  <Form.Check
                    custom
                    type="checkbox"
                    name="privacyPolicy"
                    label={
                      <>
                        Es gilt die{" "}
                        <Link to="/datenschutz" target="_blank">
                          Datenschutzerklärung
                        </Link>
                      </>
                    }
                    checked={formik.values.privacyPolicy}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={
                      formik.touched.privacyPolicy &&
                      !!formik.errors.privacyPolicy
                    }
                    feedback={formik.errors.privacyPolicy}
                  />
                </Col>
              </Form.Group>

              <Row className="d-flex justify-content-end">
                <Col md={10}>
                  <ButtonWithIcon
                    variant="light"
                    icon={
                      !isSending ? (
                        <Send />
                      ) : (
                        <Spinner animation="grow" size="sm" />
                      )
                    }
                    disabled={isSending}
                    type={!isSending ? "submit" : "button"}
                  >
                    {isSending ? "Senden…" : "Absenden"}
                  </ButtonWithIcon>
                </Col>
              </Row>
              <Row className="d-flex justify-content-end mt-2">
                <Col md={10}>
                  <small className="text-muted">
                    <span className="font-weight-bold">Hinweis:</span> Bitte die
                    mit * gekennzeichneten Felder ausfüllen.
                  </small>
                </Col>
              </Row>
            </Form>
          )}
        </Container>
      </Section>
      <Section variant="light">
        <Container>
          <Row>
            <Col md={4} className="my-2 my-md-0">
              <TextWithIcon icon={<Phone />} iconSize="sm" heading="Telefon">
                <p className="mb-0">
                  Telefon: <a href="tel:+4945468089395">04546 / 808 93 95</a>
                </p>
                <p className="mb-0">
                  Telefax: <a href="tel:+4945468089396">04546 / 808 93 96</a>
                </p>
                <p className="mb-md-0">
                  Mobil: <a href="tel:+491724504622">0172 / 450 46 22</a>
                </p>
              </TextWithIcon>
            </Col>
            <Col md={4} className="my-2 my-md-0">
              <TextWithIcon icon={<Home />} iconSize="sm" heading="Adresse">
                <p className="mb-0">Johannes Volk</p>
                <p className="mb-0">Ihlenpaul 4</p>
                <p className="mb-md-0">23911 Mustin</p>
              </TextWithIcon>
            </Col>
            <Col md={4} className="my-2 my-md-0">
              <TextWithIcon
                icon={<AlternateEmail />}
                iconSize="sm"
                heading="E-Mail"
              >
                <p className="mb-0">
                  <a href="mailto:info@hannes-volk.de">info@hannes-volk.de</a>
                </p>
                <p className="mb-md-0">
                  <Link to="/">www.hannes-volk.de</Link>
                </p>
              </TextWithIcon>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Contact;
