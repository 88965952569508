import React, { useContext } from "react";
import { CookieContext } from "../../contexts/CookieContext";
import Matomo from "../../utilities/Matomo";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";

const ShopLinks = ({ variant, className, audiobookTitle, shopLinks }) => {
  const [cookies] = useContext(CookieContext);
  const trackAudiobookLink = (goalId, shopName) => {
    if (cookies.performance) {
      Matomo.push(["trackGoal", goalId]);
      Matomo.push(["trackEvent", "Shop Link Click", audiobookTitle, shopName]);
    }
  };
  const handleAudibleClick = () => {
    trackAudiobookLink(1, "Audible");
  };
  const handleSpotifyClick = () => {
    trackAudiobookLink(2, "Spotify");
  };
  const handleThaliaClick = () => {
    trackAudiobookLink(3, "Thalia");
  };
  const values = [
    {
      shop: "Audible",
      url: shopLinks.audible_url,
      onClick: handleAudibleClick,
    },
    {
      shop: "Spotify",
      url: shopLinks.spotify_url,
      onClick: handleSpotifyClick,
    },
    {
      shop: "Thalia",
      url: shopLinks.thalia_url,
      onClick: handleThaliaClick,
    },
  ];
  return variant === "dropdown" ? (
    <Dropdown className={className}>
      <Dropdown.Toggle variant="light">Anhören</Dropdown.Toggle>
      <Dropdown.Menu>
        {values.map((value, index) => (
          <Dropdown.Item
            key={index}
            href={value.url}
            target="_blank"
            rel="noopener noreferrer"
            onClick={value.onClick}
          >
            {value.shop}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  ) : (
    <ButtonGroup aria-label="Shop links" className={className}>
      {values.map((value, index) => (
        <Button
          key={index}
          variant="light"
          href={value.url}
          target="_blank"
          rel="noopener noreferrer"
          onClick={value.onClick}
        >
          {value.shop}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default ShopLinks;
