import React, { useState, useRef, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import { ReactComponent as Logo } from "../assets/logo/hannes-volk-logo.svg";

const Navigation = () => {
  function useComponentVisible(initialIsVisible) {
    const [expanded, setExpanded] = useState(initialIsVisible);
    const ref = useRef(null);

    const handleHideDropdown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setExpanded(false);
      }
    };

    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        setExpanded(false);
      }
    };

    useEffect(() => {
      document.addEventListener("keydown", handleHideDropdown, true);
      document.addEventListener("click", handleClickOutside, true);
      return () => {
        document.removeEventListener("keydown", handleHideDropdown, true);
        document.removeEventListener("click", handleClickOutside, true);
      };
    });

    return { ref, expanded, setExpanded };
  }

  const { ref, expanded, setExpanded } = useComponentVisible(false);

  const toggleNavbar = () => {
    setExpanded(!expanded);
  };
  const closeNavbar = () => {
    setExpanded(false);
  };

  const links = [
    {
      name: "Webinar",
      route: "/webinar"
    },
    {
      name: "Hörbücher",
      route: "/hörbücher"
    },
    {
      name: "Leistungen",
      route: "/leistungen"
    },
    {
      name: "Über mich",
      route: "/über-mich"
    },
    {
      name: "Kontakt",
      route: "/kontakt"
    }
  ];
  return (
    <Navbar
      expanded={expanded}
      ref={ref}
      expand="lg"
      variant="dark"
      fixed="top"
    >
      <Container>
        <Navbar.Brand as={Link} to="/" onClick={closeNavbar}>
          <Logo className="logo" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={toggleNavbar}
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link as={NavLink} to="/" exact onClick={closeNavbar}>
              Startseite
            </Nav.Link>
            {links.map((link, index) => (
              <Nav.Link
                as={NavLink}
                to={link.route}
                key={index}
                onClick={closeNavbar}
              >
                {link.name}
              </Nav.Link>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
