import React from "react";

const TextWithIcon = ({ icon, iconSize, heading, children }) => (
  <div className="text-center px-2">
    <div className={`icon${iconSize ? `-${iconSize}` : ""} mb-3`}>{icon}</div>
    <h3>{heading}</h3>
    {children}
  </div>
);

export default TextWithIcon;
