import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const HeroImage = ({ image, orientation, children }) => {
  let imagePosition = "order-1";
  let textPosition = "order-2";
  if (orientation === "right") {
    imagePosition = "order-2";
    textPosition = "order-1";
  }
  return (
    <Row className="d-flex no-gutters">
      <Col
        md="6"
        className={`bst-hero-img ${imagePosition}`}
        style={{ backgroundImage: `url(${image})` }}
      >
        <div className="overlay"></div>
      </Col>
      <Col md={6} className={`d-flex ${textPosition}`}>
        <div className={`bst-hero-txt-${orientation} text-lg-${orientation}`}>
          {children}
        </div>
      </Col>
    </Row>
  );
};

export default HeroImage;
