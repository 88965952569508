import React from "react";

const Header = ({ image, title }) => (
  <header>
    <div
      className="bst-header-img"
      style={{
        backgroundImage: `url(${image})`,
      }}
    >
      <div className="overlay"></div>
      <div className="bst-header-flex-container">
        <div className="bst-header-caption">
          <h1>{title}</h1>
        </div>
      </div>
    </div>
  </header>
);

export default Header;
