import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import Image from "react-bootstrap/Image";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import ExpandableText from "../ExpandableText";
import Section from "../Section";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";

const AppPromotion = ({app}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const handleImageLoaded = () => {
    setImageLoaded(true);
  };
  const skeletonHeight = 320;
  const CoverSkeleton = () => (
    <div className="d-block mx-auto" style={{ maxWidth: 320 }}>
      <Skeleton height={skeletonHeight} />
    </div>
  );
  return app ? (
    <Section>
      <Container>
        <Row>
          <Col lg={5} xl={6}>
            <Image
                src={app.icon}
                alt={app.title + " - Icon"}
                className="w-100 mx-auto"
                style={{
                    maxHeight: 320,
                    maxWidth: 320,
                    borderRadius: 70,
                    display: imageLoaded ? "block" : "none",
                    boxShadow:
                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                }}
                onLoad={handleImageLoaded}
            />
            {!app.icon || !imageLoaded ? <CoverSkeleton /> : null}
          </Col>
          <Col lg={7} xl={6} className="mt-5 mt-lg-0 d-flex flex-column">
            <Row className="text-center text-lg-left">
              <Col>
                <Badge variant="light" className="mb-1">
                App
                </Badge>
                <h2>{app.title ? app.title : <Skeleton />}</h2>
                <p className="lead">
                  {app.developer ? app.developer : <Skeleton />}
                </p>
              </Col>
            </Row>
            <Row className="mt-4 mb-4">
              <Col>
                {app.description ? (
                  <ExpandableText
                    text={app.description}
                    maxCharacterCount={250}
                  />
                ) : (
                  <Skeleton count={4} />
                )}
              </Col>
            </Row>
            <Row className="mt-4 mt-lg-auto">
              {app.links ? (
                <Col className="d-flex flex-column flex-sm-row align-items-center justify-content-between">
                  <ButtonGroup aria-label="Shop links" className="mb-4 mb-sm-0">
                    {app.links.map((value, index) => (
                        <Button
                        key={index}
                        variant="light"
                        href={value.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        >
                        {value.shop}
                        </Button>
                    ))}
                  </ButtonGroup>
                </Col>
              ) : (
                <Col>
                  <Skeleton height={31} />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Container>
    </Section>
  ) : null;
};

export default AppPromotion;
