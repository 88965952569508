import React from "react";
import { Link } from "react-router-dom";
import ButtonMarketing from "./components/ButtonMarketing";
import HeaderLandingPage from "./components/HeaderLandingPage";
import Section from "./components/Section";
import ButtonLink from "./components/ButtonLink";
import TextWithIcon from "./components/TextWithIcon";
import ContainerWithIcon from "./components/ContainerWithIcon";
import HeroImage from "./components/HeroImage";
import ReferenceSlider from "./components/ReferenceSlider";
import AudiobookPromotion from "./components/audiobooks/AudiobookPromotion";
import AppPromotion from "./components/app/AppPromotion";
import HtmlHeader from "./utilities/HtmlHeader";
import { ReactComponent as FlipChart } from "./assets/icons/flip-chart.svg";
import { ReactComponent as Handshake } from "./assets/icons/handshake.svg";
import { ReactComponent as School } from "./assets/icons/school.svg";
import { ReactComponent as Computer } from "./assets/icons/computer.svg";
import CalculatorImage from "./assets/bg/accounting-black-budget-53621.jpg";
import PeopleImage from "./assets/bg/advice-advise-advisor-7096.jpg";
import CityImage from "./assets/bg/architecture-blue-sky-city-1124460.jpg";
import BuchhaltungIcon from "./assets/img/buchhaltung.png"
import { Forum } from "@material-ui/icons/";
import Container from "react-bootstrap/Container";
import Badge from "react-bootstrap/Badge";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Home = () => (
  <>
    <HtmlHeader description="Seminare in VWL, BWL, Rechnungswesen, Investition, Kalkulation, Finanzierung Controlling &amp; Optimierung auch für Ihr Unternehmen." />
    <HeaderLandingPage />
    <Section variant="sm">
      <Container className="text-center d-flex flex-column flex-md-row align-items-center justify-content-center">
        <Badge variant="primary" className="mb-1 mb-md-0 mr-md-2">
          Hörbücher
        </Badge>
        Lernen Sie mit meinen Hörbüchern doch wo Sie wollen.
        <ButtonLink as={Link} to="/hörbücher" className="mt-1 mt-md-0 ml-md-2">
          Alle Hörbücher ansehen
        </ButtonLink>
      </Container>
    </Section>
    <Section variant="light">
      <Container>
        <Row>
          <Col className="text-center">
            <h2>Leistungen</h2>
            <p className="lead">
              Johannes Volk &ndash; Volkswirt | Berater | Dozent
            </p>
          </Col>
        </Row>
        <Row className="my-4">
          <Col md={4} className="my-2">
            <TextWithIcon icon={<FlipChart />} heading="Beratung">
              <p>Analyse, Auswertung und Optimierung in Ihrem Unternehmen.</p>
            </TextWithIcon>
          </Col>
          <Col md={4} className="my-2">
            <TextWithIcon icon={<Handshake />} heading="Betreuung">
              <p>Persönliche Betreuung über den gesamten Zeitraum.</p>
            </TextWithIcon>
          </Col>
          <Col md={4} className="my-2">
            <TextWithIcon icon={<School />} heading="Schulungen">
              <p>
                Individuelle Schulungen für Ihre Mitarbeiter vor Ort oder als
                Webinar.
              </p>
            </TextWithIcon>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center">
            <ButtonMarketing as={Link} to="/leistungen">
              Mehr Informationen
            </ButtonMarketing>
          </Col>
        </Row>
      </Container>
    </Section>
    <AppPromotion app={{
      icon: BuchhaltungIcon, 
      title: "Buchhaltung - Stichworte", 
      developer: "MIVOGO", 
      description: "Bereiten Sie sich optimal auf Ihre anstehende Klausur oder Prüfung vor, lernen Sie elementare Begriffe der Buchhaltung, markieren Sie Stichworte und prüfen Sie ihr gelerntes Wissen.",
      links: [
        {
          shop: "Apple App Store",
          url: "https://apps.apple.com/de/app/buchhaltung-stichworte/id1573736255",
        },
        {
          shop: "Google Play Store",
          url: "https://play.google.com/store/apps/details?id=de.mivogo.bulletpointapp.accountancy"
        },
      ]
    }}/>
    <Container>
      <hr style={{
        marginTop: "1rem",
        marginBottom: "1rem",
        border: 0,
        borderTop: "1px solid rgba(0, 0, 0, 0.1)",
      }}/>
    </Container>
    <AudiobookPromotion />
    <Section variant="dark">
      <ContainerWithIcon
        icon={<Forum />}
        iconSize="sm"
        heading="Senden Sie Ihre Anfrage"
        button={
          <ButtonMarketing as={Link} to="/kontakt">
            Zum Kontaktformular
          </ButtonMarketing>
        }
      >
        <p>
          Sie interessieren sich für mein Angebot oder haben Fragen zu diesem?
          Senden sie mir gerne eine Nachricht über das Kontaktformular.
        </p>
      </ContainerWithIcon>
    </Section>
    <section>
      <HeroImage image={CalculatorImage} orientation="left">
        <h3>Schulungen</h3>
        <p>
          Ich biete Schulungen und Webinare unter anderem in den Bereichen
          Investition, Kalkulation und Finanzierung an.
        </p>
        <ButtonMarketing as={Link} to="/kontakt" className="mt-4">
          Jetzt Termin vereinbaren
        </ButtonMarketing>
      </HeroImage>
      <HeroImage image={PeopleImage} orientation="right">
        <h3>Beratung</h3>
        <p>
          Professionelle Beratung für Ihr Unternehmen. Optimierung, Controlling
          und vieles mehr.
        </p>
        <ButtonMarketing as={Link} to="/leistungen" className="mt-4">
          Leistungen
        </ButtonMarketing>
      </HeroImage>
      <HeroImage image={CityImage} orientation="left">
        <h3>Ökonomie</h3>
        <p>
          Meine Hauptgebiete sind Volks- und Betriebswirtschaftslehre,
          Investition, Finanzierung sowie Rechnungswesen und Controlling.
        </p>
        <ButtonMarketing as={Link} to="/über-mich" className="mt-4">
          Informationen über mich
        </ButtonMarketing>
      </HeroImage>
    </section>
    <Section variant="light">
      <ContainerWithIcon
        icon={<Computer />}
        heading="Webinare für jeden"
        button={
          <ButtonMarketing as={Link} to="/webinar">
            Mehr Informationen
          </ButtonMarketing>
        }
      >
        <p>
          Sie wollen sich von zu Hause aus weiterbilden? Dann ist ein Webinar
          Kurs genau das Richtige für Sie.
        </p>
      </ContainerWithIcon>
    </Section>
    <ReferenceSlider />
  </>
);
export default Home;
